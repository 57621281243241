export enum MessageTypeEnum {
  FIRST_LOGIN = 'firstLogin',
  FIRST_ASSESSMENT = 'firstAssessment',
}

export enum NaceCode {
  A = 'code_a',
  B = 'code_b',
  C = 'code_c',
  D = 'code_d',
  E = 'code_e',
  F = 'code_f',
  G = 'code_g',
  H = 'code_h',
  I = 'code_i',
  J = 'code_j',
  K = 'code_k',
  L = 'code_l',
  M = 'code_m',
  N = 'code_n',
  O = 'code_o',
  P = 'code_p',
  Q = 'code_q',
  R = 'code_r',
  S = 'code_s',
  T = 'code_t',
  U = 'code_u',
}

export enum SignUpSteps {
  CompanyInfo,
  Info,
  Finish,
}

export enum FeatureFlags {
  hideInviteLinkIfAssReqCompleted = 'eng-367-hide-invite-if-completed',
  showSummaryAndDetailsExport = 'eng-482-summary-and-details-export',
  insightsCopilot = 'insights-copilot',
  showLithuanian = 'eng-537-lithuanian-language',
  sustainabilityReport = 'sustainability-report',
  rebrand = 'eng-589-product-rebrand',
}

export enum ChatRole {
  USER,
  ASSISTANT,
}

export enum AssessmentInsightsArea {
  GENERAL = 'general',
  ENVIRONMENTAL = 'environmental',
  SOCIAL = 'social',
  GOVERNANCE = 'governance',
  OTHER = 'other',
}
