import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { AccountCircle } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmailIcon from '@mui/icons-material/Email';
import LogoutIcon from '@mui/icons-material/Logout';
import { Badge, Button, Divider, Grid, ListItemIcon, Stack, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useAppDispatch } from '@/app/store';
import { navigateAway, setAnswers } from '@/features/answers/answersSlice';
import { selectCurrentCompany, selectCurrentUser } from '@/features/auth/authSlice';

import LocaleSwitcher from './LocaleSwitcher';

import { answersSchema, questionsSchema } from '@/app/schema';
import { setQuestions } from '@/features/questions/questionsSlice';
import { normalizeResponse } from '@/utils/normalize';
import logo from '../../public/logo.svg';

interface HeaderProps {
  color?: string;
}

const Header: React.FC<HeaderProps> = ({ color }) => {
  const dispatch = useAppDispatch();
  const { locale, push, pathname } = useRouter();
  const t = useTranslations('Header');
  const tg = useTranslations('Generic');
  const theme = useTheme();

  const currentUser = useSelector(selectCurrentUser);
  const currentCompany = useSelector(selectCurrentCompany);
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);

  const showPromotion = useMemo(() => {
    return (currentUser && !currentCompany?.isRequester) || false;
  }, [currentUser, currentCompany]);

  const handleMenu = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenProfile = () => {
    dispatch(
      navigateAway({
        to: 'global/changeLocation',
        withPayload: { path: '/profile', locale: locale || 'en' },
      }),
    );
    setAnchorEl(null);
  };

  const handleContactUs = () => {
    window.open('mailto:support@esgrid.com', '_blank');
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    dispatch(navigateAway({ to: 'auth/clearCredentials', withPayload: locale || 'en' }));
    setAnchorEl(null);
  };

  const requesterButton = () => {
    return (
      <Button
        variant="text"
        sx={{ color: 'white', border: 0, borderBottom: '2px white' }}
        className={
          pathname?.includes('/assessment-requests') ? 'selected header-btn' : 'header-btn'
        }
        onClick={() => {
          dispatch(
            navigateAway({
              to: 'global/changeLocation',
              withPayload: {
                path: '/assessment-requests',
                locale: locale || 'en',
              },
            }),
          );
        }}
      >
        {t('requestData')}
      </Button>
    );
  };

  return (
    <AppBar>
      <Grid container justifyContent="center" spacing={2} data-nosnippet>
        <Grid item xs={12} xxl={10}>
          <Toolbar disableGutters sx={{ backgroundColor: color || theme.palette.primary.main }}>
            <Grid container alignItems="center" flexWrap="nowrap" justifyContent="space-between">
              <Grid item container flexWrap="nowrap" alignItems="center" spacing={6} marginLeft={0}>
                <Grid item>
                  <Link
                    variant="h6"
                    onClick={() => {
                      if (currentUser) {
                        dispatch(
                          navigateAway({
                            to: 'global/changeLocation',
                            withPayload: { path: '/', locale: locale || 'en' },
                          }),
                        );
                      } else {
                        push('/login', '/login', { locale: locale || 'en' });
                      }
                    }}
                    underline="none"
                    color="white"
                    sx={{ cursor: 'pointer', display: 'flex' }}
                  >
                    <Image
                      src={logo}
                      height={24}
                      width={128}
                      alt="logo"
                      color="white"
                      priority={true}
                    />
                  </Link>
                </Grid>
                {currentUser && (
                  <Grid item container flexWrap="nowrap">
                    <Stack direction="row" spacing={6}>
                      {showPromotion && (
                        <Badge
                          color="error"
                          variant="standard"
                          badgeContent={tg('new').toLowerCase()}
                        >
                          {requesterButton()}
                        </Badge>
                      )}
                      {!showPromotion && requesterButton()}
                      <Button
                        variant="text"
                        sx={{ color: 'white' }}
                        className={
                          pathname?.includes('/assessments') ? 'selected header-btn' : 'header-btn'
                        }
                        onClick={() => {
                          dispatch(
                            navigateAway({
                              to: 'global/changeLocation',
                              withPayload: { path: '/assessments/', locale: locale || 'en' },
                            }),
                          );
                          dispatch(setQuestions(normalizeResponse([], questionsSchema)));
                          dispatch(setAnswers(normalizeResponse([], answersSchema)));
                        }}
                      >
                        {t('provideData')}
                      </Button>
                    </Stack>
                  </Grid>
                )}
              </Grid>
              <Grid item container justifyContent="flex-end" paddingRight={4}>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  {currentCompany && (
                    <Grid item>
                      <Typography variant="h6" color="white" marginRight="2em">
                        {currentCompany?.name}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <LocaleSwitcher />
                  </Grid>
                  {currentUser && (
                    <Grid item>
                      <IconButton
                        href="#"
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                        sx={{ marginBottom: '1px', marginLeft: '1em' }}
                      >
                        <AccountCircle />
                      </IconButton>
                      <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={handleOpenProfile}>
                          <ListItemIcon>
                            <AccountCircleIcon fontSize="small" />
                          </ListItemIcon>
                          {t('profile')}
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleContactUs}>
                          <ListItemIcon>
                            <EmailIcon fontSize="small" />
                          </ListItemIcon>
                          {t('contactUs')}
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={handleLogout}>
                          <ListItemIcon>
                            <LogoutIcon fontSize="small" />
                          </ListItemIcon>
                          {t('logout')}
                        </MenuItem>
                      </Menu>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default Header;
